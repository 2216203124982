// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAAGWHH7L_9ybsuQo7I4eufCGtbY7oPYFE',
    authDomain: 'adorabledev.firebaseapp.com',
    databaseURL: 'https://adorabledev.firebaseio.com',
    projectId: 'adorabledev',
    storageBucket: 'adorabledev.appspot.com',
    messagingSenderId: '339665773505',
    appId: '1:339665773505:web:6f8a79a7d822744b54c540',
    measurementId: 'G-KTR0FY9FSW'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
