import {  OnInit, Component, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SocialService } from '../core/services/social.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(1500)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
  ]
})
export class HomeComponent implements OnInit {
  @ViewChild('banner') private banner: ElementRef;
  @ViewChild('aboutme') private aboutme: ElementRef;

  profileOut = 'assets/profile/profile_out.jpg';
  profileHover = 'assets/profile/profile_hover.jpg';
  profilePic = this.profileOut

  more = true;

  constructor(
    public socialService: SocialService,
    analytics: AngularFireAnalytics,
  ) {
    analytics.logEvent('viewed_home', {});
  }

  ngOnInit(): void {
    this.profilePic = this.profileOut;
  }

  moreOrLess() {
    if (this.more) {
      this.aboutme.nativeElement.scrollIntoView({
        block: 'start',  behavior: 'smooth', inline: 'nearest'
      });
    } else {
      this.banner.nativeElement.scrollIntoView({
        block: 'end',  behavior: 'smooth', inline: 'nearest'
      });
    }
    this.more = !this.more;
  }
}
