import { trigger } from '@angular/animations';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SocialService {
  socialPlatformURLs = new Map();

  constructor() {
    this.socialPlatformURLs.set('twitter', 'https://twitter.com/adorabledev_');
    this.socialPlatformURLs.set('weixin', 'weixin://dl/chat?niceppl');
    this.socialPlatformURLs.set('facebook', 'https://www.facebook.com/adorabledev/');
    this.socialPlatformURLs.set('instagram', 'https://www.instagram.com/niceppl');
    this.socialPlatformURLs.set('linkedin', 'https://www.linkedin.com/in/adorabledev/');
  }

  goToSocial(event) {
    const element: HTMLElement = event.srcElement;
    for (const [key, value] of this.socialPlatformURLs) {
      if (element.classList.contains('fa-' + key)) {
        window.open(value, '_self');
      }
    }
  }

}
