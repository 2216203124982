<mdb-navbar SideClass="navbar navbar-expand-lg navbar-light bg-white sticky-top" [containerInside]="true">

  <mdb-navbar-brand>
    <a class="navbar-brand" href="#">
      <img src="assets/logo/adev.png" height="50px" alt="">
    </a>
  </mdb-navbar-brand>

  <links>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" [routerLinkActive]="['active']" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link waves-light" [routerLink]="['/']" mdbWavesEffect>
          <mdb-icon fas icon="home fa-2x"></mdb-icon>
          <b>
            HOME
          </b>
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link waves-light" [routerLink]="['/blog']" mdbWavesEffect>
          <mdb-icon fab icon="blogger fa-2x"></mdb-icon>
          <b>
            BLOG
          </b>
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link waves-light" [routerLink]="['/about']" mdbWavesEffect>
          <mdb-icon fas icon="info fa-2x"></mdb-icon>
          <b>
            PORTFOLIO
          </b>
        </a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto nav-flex-icons">
      <li class="nav-item">
        <a class="nav-link" (click)="socialService.goToSocial($event)">
          <mdb-icon fab icon="twitter fa-2x"></mdb-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="socialService.goToSocial($event)">
          <mdb-icon fab icon="weixin fa-2x "></mdb-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="socialService.goToSocial($event)">
          <mdb-icon fab icon="facebook fa-2x"></mdb-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="socialService.goToSocial($event)">
          <mdb-icon fab icon="instagram fa-2x"></mdb-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="socialService.goToSocial($event)">
          <mdb-icon fab icon="linkedin fa-2x"></mdb-icon>
        </a>
      </li>
    </ul>
  </links>

</mdb-navbar>
