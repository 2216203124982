<div class="banner" #banner>
  <div class="container" [@simpleFadeAnimation]="'in'">
    <img alt="" class="main-picture" mat-card-image [attr.src]="profilePic" (mouseover)="profilePic = profileHover"
      (mouseout)="profilePic = profileOut">
    <div class=" name">
      <h1>
        Yunpeng Li
      </h1>
    </div>

    <div class="aka">
      <h2>
        a.k.a adorabledev
      </h2>
    </div>

    <div class="social-bar">
      <a class="social-button" (click)="socialService.goToSocial($event)">
        <mdb-icon fab icon="twitter fa-3x"></mdb-icon>
      </a>
      <a class="social-button" (click)="socialService.goToSocial($event)">
        <mdb-icon fab icon="weixin fa-3x"></mdb-icon>
      </a>
      <a class="social-button" (click)="socialService.goToSocial($event)">
        <mdb-icon fab icon="facebook fa-3x"></mdb-icon>
      </a>
      <a class="social-button" (click)="socialService.goToSocial($event)">
        <mdb-icon fab icon="instagram fa-3x"></mdb-icon>
      </a>
      <a class="social-button" (click)="socialService.goToSocial($event)">
        <mdb-icon fab icon="linkedin fa-3x"></mdb-icon>
      </a>
    </div>

    <a (click)="moreOrLess()">
      <mdb-icon fas icon="angle-double-down fa-2x" [ngClass]="more ? 'more' : 'less'"></mdb-icon>
    </a>
  </div>
</div>

<div class="about-me" #aboutme>
  <p class="about-me-content">
    My name is Yunpeng Li from Toronto, Canada. I'm currently working as a senior software engineer for Apple in the
    bay
    area. The best way to describe me as a creative, open-minded, detail-oriented, and self-motivated team player who
    is
    continuously learning and trying new technologies and ways to innovate. If you have any great ideas and want to
    try
    something together, please feel free to contact me! Cheers!
  </p>
</div>
