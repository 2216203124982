import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgMediumModule } from 'ng-medium';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AngularFirestoreModule, SETTINGS as FIRESTORE_SETTINGS} from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAnalyticsModule, ScreenTrackingService} from '@angular/fire/analytics';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HomeComponent } from './home/home.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { BlogComponent } from './blog/blog.component';
import { FooterComponent } from './footer/footer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import {MatCardModule} from "@angular/material/card";


@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    PortfolioComponent,
    BlogComponent,
    FooterComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        AngularFirestoreModule,
        BrowserAnimationsModule,
        MDBBootstrapModule.forRoot(),
        NgMediumModule,
        PdfViewerModule,
        MatCardModule,
    ],
  providers: [
    ScreenTrackingService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: FIRESTORE_SETTINGS,
      useValue: environment.production ? undefined : {
        host: 'localhost:8080',
        ssl: false
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
