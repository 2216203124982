import {  OnInit, Component, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SocialService } from '../core/services/social.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(1500)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
  ]
})
export class PortfolioComponent implements OnInit {
  pdfSrc = 'assets/resume/Yunpeng_Li_Resume_Full.pdf';

  constructor(
    public socialService: SocialService,
    analytics: AngularFireAnalytics,
  ) {
    analytics.logEvent('viewed_portfolio', {});
  }

  ngOnInit(): void {
  }
}
