import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {BlogComponent} from './blog/blog.component';
import {PortfolioComponent} from './portfolio/portfolio.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'blog', component: BlogComponent },
  { path: 'about', component: PortfolioComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
