import { style, trigger, state, transition, animate } from '@angular/animations';
import { Component, OnInit} from '@angular/core';
import { NgMediumFeedComponent } from 'ng-medium';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(1500)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
  ]
})
export class BlogComponent implements OnInit {
  constructor(
    analytics: AngularFireAnalytics,
  ) {
    analytics.logEvent('viewed_blog', {});
  }

  ngOnInit(): void {
  }

}
